<template>
  <div id="divMyDocumentsWrapper">
    <div class="content-row">
      <VueWrapperNarrow>
        <VueFaqAccordion :items="items" @itemSelect="openItem" />
      </VueWrapperNarrow>
    </div>
  </div>
</template>
<script>
import VueFaqAccordion from 'vue-faq-accordion';
import { FLEX_JUSTIFIES } from '@/constants/flex.constants.js';
import StaticContent from '@/services/Api/staticContents';
import VueWrapperNarrow from '@/components/brand/VueWrapperNarrow/VueWrapperNarrow.vue';

export default {
  name: 'MyDocumentsNavigation',
  components: {
    VueFaqAccordion,
    VueWrapperNarrow,
  },
  data() {
    return {
      items: [
        {
          id: 0,
          type: 'Sozlesme',
          title: 'Sözleşme',
          value: ' ',
        },
      ],
    };
  },
  computed: {
    constants() {
      return {
        flexAlignment: FLEX_JUSTIFIES,
      };
    },
  },
  methods: {
    openItem(openItem) {
      let selectItem = this.items.find(item => {
        return item.id === openItem.itemIndex;
      });
      let staticContentObj = JSON.parse(JSON.stringify(selectItem));
      if (staticContentObj.value === ' ') {
        StaticContent.getStaticContent(staticContentObj.type).then(res => {
          if (res.data.Data) {
            this.items[staticContentObj.id].value = res.data.Data.content;
          }
        });
      }
    },
  },
};
</script>
<style scoped lang="scss">
.content-row {
  height: unset;
  position: relative;
}
/deep/ .faq-wrapper {
  .accordion {
    border: none;
    .accordion__item {
      margin-bottom: 10px;
      border: none;
      .accordion__title {
        background: palette-color-level('grey', '10');
        .accordion__title-text {
          font-size: 21px;
          font-weight: 400;
          color: palette-color-level('grey', '40');
        }
        .accordion__toggle-button {
          background: url('~@/assets/icons/right-arrow-icon.svg');
          -webkit-background-size: contain;
          -moz-background-size: contain;
          background-size: contain;
          &:after {
            display: none;
          }
          &:before {
            display: none;
          }
        }
        .accordion__toggle-button_active {
          transform: rotate(270deg);
        }
      }
      .accordion__value {
        font-size: 14px;
        line-height: 24px;
        color: palette-color-level('grey', '40');
        padding: 20px 0 40px 0;
      }
    }
  }
}
</style>
